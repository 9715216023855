import React from 'react'
import Layout from '../components/layout'

class About extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout seoTitle="Psychotherapie | Praxis für Psychotherapie Telgte">
        <div id="main">
          <section id="intro" className="main">
            <div>
              <header className="major">
                <h2>Psychotherapie</h2>
              </header>
              <h3>
                <strong>
                  <span>
                    Was geschieht im Rahmen einer psychotherapeutischen
                    Behandlung?
                  </span>
                </strong>
              </h3>
              <p>
                <span>
                  Grundlage einer Psychotherapie – unabhängig von der
                  Therapierichtung – ist immer eine ernsthafte, von
                  gegenseitiger Akzeptanz und Wertschätzung geprägte Begegnung,
                  in der im Verlauf der gemeinsamen Arbeit ein tragfähiger
                  Kontakt entsteht. Ausgehend von den aktuellen Belastungen und
                  Problemen sollen zugrundeliegende psychische Konflikte und
                  ungüngstige Strategien, die zur Entstehung der Probleme
                  beitragen, bearbeitet werden, um eine Verbesserung der
                  psychischen Belastbarkeit und Lebensqualität zu erreichen.
                </span>
              </p>
              <p>
                <span>
                  Die Ziele einer Psychotherapie werden ausgehend von der
                  gestellten Diagnose und den aktuellen Problemen gemeinsam
                  festgelegt und ein Behandlungsplan vereinbart.{' '}
                </span>
              </p>
              <p>
                <span>
                  Sie erhalten professionelle Unterstützung dabei,
                  herauszufinden, was Sie aktuell daran hindert, ein möglichst
                  gesundes, gelungenes Leben zu führen und welche Änderungen Sie
                  herbeiführen und welche Strategien Sie im Alltag einsetzen
                  müssen, um ihre Probleme zu bewältigen.{' '}
                </span>
              </p>
              <p>
                <span>
                  Nach der Therapiedurchführung erfolgen eine gemeinsame
                  Auswertung und der Abschluss der Behandlung.{' '}
                </span>
              </p>
              <p>
                <span>
                  In einem Telefonkontakt etwa 6 Monaten nach Therapieende
                  vergewissere ich mich gemeinsam mit Ihnen über die Stabilität
                  des Therapieerfolgs oder über notwendige weitere
                  Unterstützung.
                </span>
              </p>
              <p>
                <span>
                  Ich bin zur Durchführung und Abrechnung von Verhaltenstherapie
                  mit Erwachsenen zu Lasten der Gesetzlichen und Privaten
                  Krankenkassen zugelassen. Eine Verhaltenstherapie als
                  Kurzzeittherapie dauert bis zu 25 Stunden. Sollte dies nicht
                  ausreichend sein, ist es möglich, über Verlängerungsanträge
                  bei Gesetzlichen Krankenkassen auch 45 oder bis zu 60 Stunden
                  bewilligt zu bekommen. Für Privatversicherte gelten ggf
                  abweichend jeweils die Regelungen der geschlossenen Verträge.
                </span>
              </p>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default About
